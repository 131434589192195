import React from 'react';

import DealersBlock from '@/modules/Service/blocks/DealersBlock';
import { useTypedSelector } from '@/store/store';
import {
  getServiceBrandLanding,
  getServiceBrands,
} from '@/store/reducers/service';
import {
  getServiceDealersData,
  getServicePageOptions,
  handleServiceClick,
} from '@/modules/Service/common.helpers';
import { useServiceContext } from '@/modules/Service/common.hooks';

function Dealers() {
  const serviceBrands = useTypedSelector(getServiceBrands);
  const serviceBrandLanding = useTypedSelector(getServiceBrandLanding);
  const { setSelectedDealer } = useServiceContext();

  if (!serviceBrandLanding) {
    return null;
  }

  const { brands, cities } = getServicePageOptions(
    serviceBrandLanding.serviceCenters,
    serviceBrands
  );
  const dealers = getServiceDealersData(serviceBrandLanding.serviceCenters);

  return (
    <DealersBlock
      id={2}
      title="Сервисные центры"
      dealers={dealers.map((dealer) => ({
        ...dealer,
        onClick: (dealer) => handleServiceClick(dealer, setSelectedDealer),
      }))}
      options={{ brands, cities }}
    />
  );
}

export default Dealers;
