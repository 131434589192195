import React from 'react';

import BenefitsBlock from '@/modules/Service/blocks/BenefitsBlock';
import { useTypedSelector } from '@/store/store';
import { getServiceBrandLanding } from '@/store/reducers/service';
import { colors } from '@/constants/theme';
import { useStyledComponentsTheme } from '@/theme/hooks';

function Benefits() {
  const serviceBrandLanding = useTypedSelector(getServiceBrandLanding);

  const theme = useStyledComponentsTheme();

  if (!serviceBrandLanding) {
    return null;
  }

  const { statsImage, statsTitle, statsTitleColor, statsData } =
    serviceBrandLanding;

  return (
    <BenefitsBlock
      image={{
        url: statsImage.desktop,
      }}
      imageMobile={{ url: statsImage.mobile }}
      title={statsTitle ?? ''}
      titleColor={statsTitleColor === 'PRIMARY' ? colors.main : theme.black}
      benefits={
        statsData?.map(({ icon, description, descriptionColor }) => ({
          icon: { url: icon },
          description: description ?? '',
          descriptionColor:
            descriptionColor === 'PRIMARY' ? colors.main : theme.black,
        })) ?? []
      }
    />
  );
}

export default Benefits;
