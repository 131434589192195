import React from 'react';
import { SwiperSlide } from 'swiper/react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import PostCard from '@/components/PostCard';
import { SliderWrapper } from '@/components/LandingWidgets';
import { media } from '@/utils/mixin';

import { PostCardSliderProps } from './types';

function PostCardSlider({ id, posts }: PostCardSliderProps) {
  const isMobile = useMedia('(max-width: 767px)');

  if (!posts || posts.length === 0) {
    return null;
  }

  return (
    <Component>
      <SliderWrapper
        id={id}
        options={{
          slidesPerView: !isMobile ? undefined : 1,
          withoutNavs: posts.length < 5 || isMobile,
          withoutPagination: !isMobile,
          spaceBetween: 20,
        }}
      >
        {posts.map((post, index) => (
          <SwiperSlide key={index}>
            <PostCard {...post} />
          </SwiperSlide>
        ))}
      </SliderWrapper>
    </Component>
  );
}

export default PostCardSlider;

const Component = styled.div`
  .swiper-slide {
    max-width: 290px;
    height: auto;

    ${media.mobile(css`
      max-width: none;
    `)}
  }
`;
