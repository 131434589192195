import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/Service/common.styles';
import { useTypedSelector } from '@/store/store';
import { getServiceBrandServiceLanding } from '@/store/reducers/service';

function About() {
  const serviceBrandServiceLanding = useTypedSelector(
    getServiceBrandServiceLanding
  );

  if (!serviceBrandServiceLanding) {
    return null;
  }

  const { brand, brandRu, name, phone } = serviceBrandServiceLanding;

  return (
    <Component>
      <ContentContainer>
        <ServiceDescription
          dangerouslySetInnerHTML={{
            __html: `
                  В сервисных центрах «Атлант-М» представлен широкий спектр услуг по
          ремонту и обслуживанию авто. В перечень оказываемых услуг сервиса
          включена и такая услуга, как “${name}” для автомобилей ${brand}.
          Сервисные центры «Атлант-М» предоставляют гарантию на все виды работ
          для автомобилей марки ${brandRu}. Сертифицированные специалисты наших
          автосервисов используют качественные материалы и запчасти, а также
          стремятся обеспечить индивидуальный подход к каждому клиенту. ${name}
          для ${brand} в «Атлант-М» является оптимальным сочетанием качества
          выполненных работ и их стоимости.
          <br/><br/>
          Получить консультацию по ремонту и
          обслуживанию автомобиля ${brand} Вы можете у менеджера компании по
          телефону <b>${phone}</b> либо оставив заявку.
        `,
          }}
        />
      </ContentContainer>
    </Component>
  );
}

export default About;

const Component = styled.section`
  margin: 40px 0;
`;

const ServiceDescription = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.blue100};
`;
