import React from 'react';

import OffersBlock from '@/modules/Service/blocks/OffersBlock';
import {
  getServicePosts,
  getServiceSingleLanding,
} from '@/store/reducers/service';
import { useTypedSelector } from '@/store/store';

function Offers() {
  const servicePosts = useTypedSelector(getServicePosts);
  const serviceSingleLanding = useTypedSelector(getServiceSingleLanding);

  if (
    !servicePosts ||
    servicePosts.length === 0 ||
    !serviceSingleLanding?.blogTag
  ) {
    return null;
  }

  return (
    <OffersBlock
      id={2}
      title={serviceSingleLanding.blogTitle ?? 'Акции по сервису'}
      offers={servicePosts.map(({ title, url, image, landscapeImage }) => ({
        title: title ?? '',
        url: url ?? '',
        image:
          servicePosts.length !== 1 ? image : landscapeImage ?? { url: '' },
      }))}
    />
  );
}

export default Offers;
