import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ContentContainer } from '@/modules/Service/common.styles';
import ServiceBanner from '@/components/ServiceBanner';
import { Header as SliderWrapperHeader } from '@/components/LandingWidgets/components/SliderWrapper';
import PostCardSlider from '@/components/PostCardSlider';

import { OffersBlockProps } from './types';

function OffersBlock({ id, title, offers }: OffersBlockProps) {
  if (!offers || offers.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{title}</Title>

        <ContentWrapper>
          {offers.length !== 1 ? (
            <PostCardSlider id={id} posts={offers} />
          ) : (
            <ServiceBanner
              title={offers[0].title ?? ''}
              image={offers[0].image}
              link={offers[0].url ?? ''}
            />
          )}
        </ContentWrapper>
      </ContentContainer>
    </Component>
  );
}

export default OffersBlock;

const Component = styled.section`
  position: relative;
  padding: 30px 0;

  ${media.mobile(css`
    padding: 20px 0;
  `)}
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  `)}
`;

const ContentWrapper = styled.div`
  margin-top: 30px;

  ${media.tablet(css`
    margin-top: 20px;
  `)}

  ${SliderWrapperHeader} {
    top: -70px;

    ${media.tablet(
      css`
        top: -50px;
      `
    )}
  }
`;
