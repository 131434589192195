import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { useServiceMainData } from '@/modules/Service/pages/ServiceMain/hooks';
import Picture from '@/components/Picture';
import { ContentContainer } from '@/modules/Service/common.styles';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { handleServiceClick } from '@/modules/Service/common.helpers';
import Button from '@/components/Button';
import { handleScrollToDomElement } from '@/utils/scroll';

function Hero() {
  const {
    heroTitle,
    heroSubtitle,
    heroFirstButtonTitle,
    heroSecondButtonTitle,
    heroImage,
    heroMobileImage,
  } = useServiceMainData();

  return (
    <Component>
      <Background
        loading="lazy"
        desktop={convertThumbnailToPictureImage(heroImage)}
        laptop={convertThumbnailToPictureImage(heroImage)}
        tabletSmall={convertThumbnailToPictureImage(heroImage)}
        mobile={convertThumbnailToPictureImage(heroMobileImage)}
      />

      <Content>
        <ContentContainer>
          <ContentInner>
            <Title>{heroTitle}</Title>
            <Subtitle>{heroSubtitle}</Subtitle>
            <Actions>
              {heroFirstButtonTitle && (
                <Action
                  variant="contained"
                  onClick={() => handleServiceClick()}
                >
                  {heroFirstButtonTitle}
                </Action>
              )}
              {heroSecondButtonTitle && (
                <Action
                  variant="contained"
                  onClick={() => handleScrollToDomElement('consultation')}
                >
                  {heroSecondButtonTitle}
                </Action>
              )}
            </Actions>
          </ContentInner>
        </ContentContainer>
      </Content>
    </Component>
  );
}

export default Hero;

const Component = styled.section`
  position: relative;
`;

const Background = styled(Picture)`
  padding-top: 20.229%;

  ${media.tablet(css`
    padding-top: 30.229%;
  `)}

  ${media.mobile(css`
    padding-top: 69.446%;
  `)}

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 40px;
  width: 100%;

  ${media.tablet(css`
    top: 20px;
  `)}

  ${media.mobile(css`
    position: relative;
    top: auto;
    margin-top: 20px;
  `)}
`;

const ContentInner = styled.div`
  max-width: 660px;

  ${media.tablet(css`
    max-width: 450px;
  `)}

  ${media.mobile(css`
    max-width: none;
  `)}
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  margin: 0;

  ${media.tablet(css`
    font-size: 30px;
    line-height: 35px;
  `)}

  ${media.mobile(css`
    color: ${(props) => props.theme.black};
  `)}
`;

const Subtitle = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-top: 20px;
  color: ${colors.blue600};

  ${media.mobile(css`
    color: ${(props) => props.theme.blue100};
  `)}
`;

const Actions = styled.div`
  display: flex;
  margin-top: 40px;

  ${media.tablet(css`
    margin-top: 20px;
  `)}

  ${media.mobile(css`
    flex-direction: column;
    margin-top: 40px;
  `)}
`;

const Action = styled(Button)`
  max-width: 300px;
  padding: 14px 0;

  ${media.mobile(css`
    max-width: none;
  `)}

  &:not(:first-child) {
    margin-left: 20px;

    ${media.mobile(css`
      margin: 20px 0 0;
    `)}
  }
`;
