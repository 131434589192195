import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/Service/common.styles';
import ServicesBlock from '@/modules/Service/blocks/ServicesBlock';
import { media } from '@/utils/mixin';
import { useServiceMainData } from '@/modules/Service/pages/ServiceMain/hooks';
import { Breadcrumbs } from '@/components/Breadcrumbs';

function Services() {
  const { serviceMaintenanceTitle, serviceMaintenanceItems } =
    useServiceMainData();

  if (!serviceMaintenanceItems || serviceMaintenanceItems.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <BreadcrumbsWrapper>
          <Breadcrumbs
            links={[
              { label: 'Главная', link: '/' },
              { label: 'Сервис', link: '/service' },
            ]}
          />
        </BreadcrumbsWrapper>
      </ContentContainer>

      <ServicesBlockWrapper>
        <ServicesBlock
          id={1}
          title={serviceMaintenanceTitle ?? ''}
          items={serviceMaintenanceItems.map(
            ({ image, title, description, link }) => ({
              image,
              title: title ?? '',
              description: description ?? '',
              link: link ?? '',
            })
          )}
        />
      </ServicesBlockWrapper>
    </Component>
  );
}

export default Services;

const Component = styled.section`
  padding: 30px 0;

  ${media.mobile(css`
    padding: 20px 0;
  `)}
`;

const BreadcrumbsWrapper = styled.div`
  margin-left: 311px;

  ${media.tablet(css`
    display: none;
  `)}
`;

const ServicesBlockWrapper = styled.div`
  margin-top: 20px;

  ${media.tablet(css`
    margin-top: 0;
  `)}
`;
