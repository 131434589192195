import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/Service/common.styles';
import ServiceForm from '@/components/Forms/ServiceForm';
import { media } from '@/utils/mixin';
import { useTypedSelector } from '@/store/store';
import {
  getServiceBrandLanding,
  getServiceBrands,
} from '@/store/reducers/service';
import {
  getServicePageOptions,
  handleSubmitServiceForm,
} from '@/modules/Service/common.helpers';
import { initialServiceFormValues } from '@/components/Forms/ServiceForm/helpers';
import { useServiceContext } from '@/modules/Service/common.hooks';

function Form() {
  const serviceBrands = useTypedSelector(getServiceBrands);
  const serviceBrandLanding = useTypedSelector(getServiceBrandLanding);
  const { selectedDealer } = useServiceContext();

  if (!serviceBrandLanding) {
    return null;
  }

  const { brands, dealers } = getServicePageOptions(
    serviceBrandLanding.serviceCenters,
    serviceBrands
  );

  const foundDealer = (selectedDealer &&
    dealers.find((dealer) => dealer.dealer.label.includes(selectedDealer.name))
      ?.dealer) ?? { label: '', value: '' };

  const foundBrand = brands.find(
    (brand) =>
      brand.label.toLowerCase() === serviceBrandLanding?.name?.toLowerCase()
  );

  if (
    !foundBrand ||
    !serviceBrandLanding?.popularServiceList ||
    serviceBrandLanding.popularServiceList.length === 0
  ) {
    return null;
  }

  return (
    <Component id="service">
      <ContentContainer>
        <ServiceForm
          buttonTitle="Записаться"
          options={{
            brands: [foundBrand],
            dealers,
            services: [
              ...serviceBrandLanding.popularServiceList,
              { label: 'Другое', value: 'Другое' },
            ],
          }}
          initialFields={{
            ...initialServiceFormValues,
            brand: foundBrand,
          }}
          setFields={{ dealer: foundDealer }}
          disabledFields={{ brand: true }}
          onSubmit={(values) =>
            handleSubmitServiceForm(
              values,
              serviceBrandLanding?.formEvent ?? ''
            )
          }
        />
      </ContentContainer>
    </Component>
  );
}

export default Form;

const Component = styled.section`
  padding: 30px 0;

  ${media.mobile(css`
    padding: 20px 0;
  `)}
`;
