import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import ServiceDealerCard from '../ServiceDealerCard';

import { ServiceDealersListProps } from './types';

function ServiceDealersList({ dealers }: ServiceDealersListProps) {
  if (!dealers || dealers.length === 0) {
    return null;
  }

  return (
    <Component>
      {dealers.map((dealer, index) => (
        <ServiceDealerCard key={index} {...dealer} />
      ))}
    </Component>
  );
}

export default ServiceDealersList;

const Component = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  ${media.tablet(css`
    grid-template-columns: repeat(1, 1fr);
  `)}
`;
