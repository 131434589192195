import React from 'react';
import { SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import SwiperCore, { Pagination } from 'swiper';

import { SliderWrapper } from '@/components/LandingWidgets';

import ServiceDealerCard from '../ServiceDealerCard';

import { ServiceDealersSliderProps } from './types';

SwiperCore.use([Pagination]);

function ServiceDealersSlider({ id, dealers }: ServiceDealersSliderProps) {
  if (!dealers || dealers.length === 0) {
    return null;
  }

  return (
    <Component>
      <SliderWrapper
        id={id}
        options={{
          withoutNavs: true,
          spaceBetween: 20,
        }}
      >
        {dealers.map((dealer, index) => (
          <SwiperSlide key={index}>
            <ServiceDealerCard {...dealer} />
          </SwiperSlide>
        ))}
      </SliderWrapper>
    </Component>
  );
}

export default ServiceDealersSlider;

const Component = styled.div`
  position: relative;

  .swiper-slide {
    height: auto;
  }
`;
