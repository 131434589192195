import React from 'react';

import ServiceForm from '@/components/Forms/ServiceForm';
import {
  getServicePageOptions,
  handleSubmitServiceForm,
} from '@/modules/Service/common.helpers';
import { useTypedSelector } from '@/store/store';
import {
  getServiceBrands,
  getServiceBrandServiceLanding,
} from '@/store/reducers/service';
import { OptionType } from '@/typings/common';
import { initialServiceFormValues } from '@/components/Forms/ServiceForm/helpers';
import { useServiceContext } from '@/modules/Service/common.hooks';

function Form() {
  const serviceBrands = useTypedSelector(getServiceBrands);
  const serviceBrandServiceLanding = useTypedSelector(
    getServiceBrandServiceLanding
  );
  const { selectedDealer } = useServiceContext();

  if (!serviceBrandServiceLanding) {
    return null;
  }

  const { brands, dealers } = getServicePageOptions(
    serviceBrandServiceLanding.serviceCenters,
    serviceBrands
  );

  const foundDealer = (selectedDealer &&
    dealers.find((dealer) => dealer.dealer.label.includes(selectedDealer.name))
      ?.dealer) ?? { label: '', value: '' };

  const {
    name,
    brand: serviceBrand,
    formTitle,
    formEvent,
    leadType,
  } = serviceBrandServiceLanding;

  const foundBrand = brands.find(
    (brand) => brand.label.toLowerCase() === serviceBrand.toLowerCase()
  );

  const service: OptionType = { label: name, value: name };

  if (!foundBrand) {
    return null;
  }

  return (
    <ServiceForm
      title={formTitle ?? undefined}
      buttonTitle="Записаться"
      options={{ brands: [foundBrand], dealers, services: [service] }}
      initialFields={{
        ...initialServiceFormValues,
        brand: foundBrand,
        service,
      }}
      setFields={{ dealer: foundDealer }}
      disabledFields={{ brand: true, service: true }}
      onSubmit={(values) =>
        handleSubmitServiceForm(values, formEvent ?? '', leadType)
      }
      isMobile
    />
  );
}

export default Form;
