import React from 'react';
import styled, { css } from 'styled-components';
import { isEmpty } from 'lodash';

import { useMedia } from '@tager/web-core';

import { ContentContainer } from '@/modules/Service/common.styles';
import { media } from '@/utils/mixin';
import ServiceList from '@/modules/Service/components/ServiceList';
import { useTypedSelector } from '@/store/store';
import { getServiceBrandLanding } from '@/store/reducers/service';
import { getServiceListColumns } from '@/modules/Service/common.helpers';

function Services() {
  const isTablet = useMedia('(max-width: 1259px)');
  const serviceBrandLanding = useTypedSelector(getServiceBrandLanding);

  if (
    !serviceBrandLanding?.serviceList ||
    serviceBrandLanding?.serviceList.length === 0 ||
    isEmpty(serviceBrandLanding.serviceList[0])
  ) {
    return null;
  }

  const chunkedServices = getServiceListColumns(
    serviceBrandLanding.serviceList,
    !isTablet ? 3 : 2
  );

  return (
    <Component>
      <ContentContainer>
        <Title>Услуги</Title>

        <List>
          {chunkedServices.map((services, index) => (
            <ListColumn key={index}>
              {services.items.map(({ title, url, children }, index) => (
                <ListItem key={index}>
                  <ServiceList title={title} url={url} children={children} />
                </ListItem>
              ))}
            </ListColumn>
          ))}
        </List>
      </ContentContainer>
    </Component>
  );
}

export default Services;

const Component = styled.section`
  padding: 30px 0;

  ${media.mobile(css`
    padding: 20px 0;
  `)}
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  `)}
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 84px;
  margin-top: 20px;

  ${media.tablet(css`
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  `)}

  ${media.mobile(css`
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    margin-top: 11px;
  `)}
`;

const ListColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.div`
  &:not(:first-child) {
    margin-top: 40px;

    ${media.mobile(css`
      margin-top: 10px;
    `)}
  }
`;
