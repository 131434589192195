import * as Yup from 'yup';

import { OptionType } from '@/typings/common';

import { ServiceFormFields } from './types';

export const initialServiceFormValues: ServiceFormFields = {
  name: '',
  brand: { label: '', value: '' },
  dealer: { label: '', value: '' },
  phone: '',
  service: { label: '', value: '' },
  date: '',
  comment: '',
};

export const validationServiceFormSchema: Yup.SchemaOf<ServiceFormFields> =
  Yup.object().shape({
    name: Yup.string().required('Введите имя'),
    brand: Yup.object({
      label: Yup.string().required('Выберите марку автомобиля'),
      value: Yup.string().required('Выберите марку автомобиля'),
    }),
    dealer: Yup.object({
      label: Yup.string().required('Выберите автоцентр'),
      value: Yup.string().required('Выберите автоцентр'),
    }),
    phone: Yup.string().required('Введите телефон'),
    service: Yup.object({
      label: Yup.string().required('Выберите услугу'),
      value: Yup.string().required('Выберите услугу'),
    }),
    date: Yup.string().required('Выберите дату'),
    comment: Yup.string(),
  });

export const getDealersOptions = (
  dealers: { dealer: OptionType; brands: OptionType[] }[]
): OptionType[] => {
  return dealers.map((dealerItem) => ({
    label: dealerItem.dealer.label,
    value: dealerItem.dealer.value,
  }));
};

export const getDealersOptionsByBrand = (
  dealers: { dealer: OptionType; brands: OptionType[] }[],
  searchBrand: string
): OptionType[] => {
  return dealers
    .filter((dealer) =>
      dealer.brands.some((brand) => brand.value === searchBrand)
    )
    .map((dealerItem) => ({
      label: dealerItem.dealer.label,
      value: dealerItem.dealer.value,
    }));
};
