import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';

import { ServiceNodeProps } from './types';

function ServiceNode({ title, url, children }: ServiceNodeProps) {
  return (
    <Component>
      <Node to={url}>{title}</Node>
      {children &&
        children.map((node, index) => <ServiceNode key={index} {...node} />)}
    </Component>
  );
}

export default ServiceNode;

const Component = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  margin-left: 14px;

  ${media.mobile(css`
    margin-top: 0;
  `)}
`;

const Node = styled(Link)`
  position: relative;
  padding-left: 14px;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  color: ${(props) => props.theme.blue100};

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 12px;
    width: 9px;
    height: 2px;
    background: ${colors.main};
  }

  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    color: ${colors.main};
  }
`;
