import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/Service/common.styles';
import { media } from '@/utils/mixin';
import ConsultationFormSecondary from '@/components/Forms/ConsultationFormSecondary';
import { useServiceMainData } from '@/modules/Service/pages/ServiceMain/hooks';
import { ConsultationFormSecondaryFields } from '@/components/Forms/ConsultationFormSecondary/types';
import { submitServiceConsultation } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';

function Consultation() {
  const {
    consultationFormImage,
    consultationFormImageMobile,
    consultationFormTitle,
    consultationFormButtonText,
    consultationFormGoogleEvent,
  } = useServiceMainData();

  if (
    !consultationFormImage ||
    !consultationFormTitle ||
    !consultationFormButtonText
  ) {
    return null;
  }

  const sendConsultationForm = async (
    values: ConsultationFormSecondaryFields
  ): Promise<void> => {
    try {
      await submitServiceConsultation(values).then(() => {
        if (consultationFormGoogleEvent) {
          googleEvent(consultationFormGoogleEvent);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  return (
    <Component id="consultation">
      <ContentContainer>
        <ConsultationFormSecondary
          image={consultationFormImage}
          imageMobile={consultationFormImageMobile}
          title={consultationFormTitle ?? ''}
          buttonText={consultationFormButtonText ?? ''}
          fieldsConfig={{ withMessage: true }}
          onSubmit={sendConsultationForm}
        />
      </ContentContainer>
    </Component>
  );
}

export default Consultation;

const Component = styled.section`
  padding: 30px 0;

  ${media.mobile(css`
    padding: 20px 0;
  `)}
`;
