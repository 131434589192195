import React from 'react';

import BenefitsBlock from '@/modules/Service/blocks/BenefitsBlock';
import { useServiceMainData } from '@/modules/Service/pages/ServiceMain/hooks';
import { colors } from '@/constants/theme';
import { useStyledComponentsTheme } from '@/theme/hooks';

function Benefits() {
  const {
    benefitsImage,
    benefitsImageMobile,
    benefitsTitle,
    benefitsTitleColor,
    benefitsItems,
    benefitsButtonTitle,
    benefitsButtonLink,
  } = useServiceMainData();

  const theme = useStyledComponentsTheme();

  if (!benefitsTitle || !benefitsItems || benefitsItems.length === 0) {
    return null;
  }

  return (
    <BenefitsBlock
      image={benefitsImage}
      imageMobile={benefitsImageMobile}
      title={benefitsTitle}
      titleColor={benefitsTitleColor === 'PRIMARY' ? colors.main : theme.black}
      benefits={benefitsItems.map(
        ({ icon, description, descriptionColor }) => ({
          icon,
          description: description ?? '',
          descriptionColor:
            descriptionColor === 'PRIMARY' ? colors.main : theme.black,
        })
      )}
      buttonTitle={benefitsButtonTitle ?? ''}
      buttonLink={benefitsButtonLink ?? ''}
    />
  );
}

export default Benefits;
