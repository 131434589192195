import React, { useLayoutEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { FormikErrors, useFormik } from 'formik';
import moment from 'moment';

import { convertRequestErrorToMap, useUpdateEffect } from '@tager/web-core';

import SuccessForm from '@/components/Forms/SuccessForm';
import { CheckboxAgreement } from '@/components/Checkbox';
import StockFilterSelect from '@/components/StockFilterSelect';
import StockFilterInput from '@/components/StockFilterInput';
import StockFilterTextArea from '@/components/StockFilterTextArea';
import StockFilterDatePicker from '@/components/StockFilterDatePicker';
import { media } from '@/utils/mixin';
import Loader from '@/components/Loader';
import { OptionType } from '@/typings/common';
import { getTomorrowDate } from '@/utils/date';
import Button from '@/components/Button';

import { ServiceFormFields, ServiceFormProps } from './types';
import {
  getDealersOptions,
  getDealersOptionsByBrand,
  initialServiceFormValues,
  validationServiceFormSchema,
} from './helpers';

function ServiceForm({
  title = 'Запись на сервис',
  buttonTitle,
  options,
  initialFields,
  disabledFields,
  setFields,
  onSubmit,
  isMobile,
}: ServiceFormProps) {
  const [isFormSend, setFormSend] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);

  const [brand, setBrand] = useState<OptionType>({ label: '', value: '' });
  const [dealer, setDealer] = useState<OptionType>({ label: '', value: '' });
  const [service, setService] = useState<OptionType>({ label: '', value: '' });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    setErrors,
    isSubmitting,
  } = useFormik<ServiceFormFields>({
    initialValues: initialServiceFormValues,
    validationSchema: validationServiceFormSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        await onSubmit?.(values).then(() => setFormSend(true));
      } catch (error: any) {
        const errorMap = convertRequestErrorToMap(
          error
        ) as FormikErrors<ServiceFormFields>;
        setErrors(errorMap);
        console.error(error);
      }
    },
  });

  useLayoutEffect(() => {
    if (initialFields) {
      setValues(initialFields);

      if (initialFields.brand) {
        setBrand(initialFields.brand);
      }

      if (initialFields.dealer) {
        setDealer(initialFields.dealer);
      }

      if (initialFields.service) {
        setService(initialFields.service);
      }
    }
  }, []);

  useUpdateEffect(() => {
    setFieldValue('brand', brand);

    if (dealer.value) {
      const foundDealer = options?.dealers
        ?.find((currentDealer) => currentDealer.dealer.value === dealer.value)
        ?.brands.find((currentBrand) => currentBrand.value === brand.value);

      if (!foundDealer) {
        setFieldValue('dealer', { label: '', value: '' });
        setDealer({ label: '', value: '' });
      }
    }
  }, [brand]);

  useUpdateEffect(() => {
    setFieldValue('dealer', dealer);
  }, [dealer]);

  useUpdateEffect(() => {
    setFieldValue('service', service);
  }, [service]);

  useUpdateEffect(() => {
    if (setFields?.name && setFields.name) {
      setFieldValue('name', setFields.name);
    }

    if (setFields?.brand && setFields.brand.value) {
      setFieldValue('brand', setFields.brand);
      setBrand(setFields.brand);
    }

    if (setFields?.dealer && setFields.dealer.value) {
      setFieldValue('dealer', setFields.dealer);
      setDealer(setFields.dealer);

      if (brand.value && !disabledFields?.brand) {
        setFieldValue('brand', { label: '', value: '' });
        setBrand({ label: '', value: '' });
      }
    }

    if (setFields?.phone && setFields.phone) {
      setFieldValue('phone', setFields.phone);
    }

    if (setFields?.service && setFields.service) {
      setFieldValue('service', setFields.service);
      setService(setFields.service);
    }

    if (setFields?.date && setFields.date) {
      setFieldValue('date', setFields.date);
    }

    if (setFields?.comment && setFields.comment) {
      setFieldValue('comment', setFields.comment);
    }
  }, [setFields]);

  const dealersOptions = values.brand.value
    ? getDealersOptionsByBrand(options?.dealers ?? [], values.brand.value)
    : getDealersOptions(options?.dealers ?? []);

  return (
    <Component
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      isMobile={isMobile}
    >
      {!isFormSend ? (
        <React.Fragment>
          <Title isMobile={isMobile}>{title}</Title>
          <Main>
            <FieldsGroup isMobile={isMobile}>
              <Field>
                <FieldInner>
                  <StockFilterInput
                    id="name"
                    name="name"
                    label="Имя"
                    value={values.name}
                    onChange={handleChange}
                    errorMessage={
                      errors.name && touched.name ? errors.name : undefined
                    }
                  />
                </FieldInner>
              </Field>

              <Field>
                <FieldInner>
                  <StockFilterSelect
                    label="Марка автомобиля"
                    value={brand}
                    options={options?.brands ?? []}
                    onChange={setBrand}
                    disabled={disabledFields?.brand}
                    errorMessage={
                      errors.brand?.value && touched.brand?.value
                        ? errors.brand.value
                        : undefined
                    }
                  />
                </FieldInner>
              </Field>

              <Field>
                <FieldInner>
                  <StockFilterSelect
                    label="Автоцентр"
                    value={dealer}
                    options={dealersOptions}
                    onChange={setDealer}
                    errorMessage={
                      errors.dealer?.value && touched.dealer?.value
                        ? errors.dealer.value
                        : undefined
                    }
                  />
                </FieldInner>
              </Field>

              <Field>
                <FieldInner>
                  <StockFilterInput
                    id="phone"
                    name="phone"
                    label="Телефон"
                    value={values.phone}
                    onChange={handleChange}
                    placeholder="+375 (__) ___-__-__"
                    errorMessage={
                      errors.phone && touched.phone ? errors.phone : undefined
                    }
                  />
                </FieldInner>
              </Field>

              <Field>
                <FieldInner>
                  <StockFilterSelect
                    label="Услуга"
                    value={service}
                    options={options?.services ?? []}
                    onChange={setService}
                    disabled={disabledFields?.service}
                    errorMessage={
                      errors.service?.value && touched.service?.value
                        ? errors.service.value
                        : undefined
                    }
                  />
                </FieldInner>
              </Field>

              <Field>
                <FieldInner>
                  <StockFilterDatePicker
                    id="date"
                    name="date"
                    label="Дата"
                    selected={values.date ? new Date(values.date) : null}
                    onChange={(date) =>
                      setFieldValue(
                        'date',
                        moment(date as Date | null).format('YYYY-MM-DD')
                      )
                    }
                    locale="ru"
                    dateFormat={['dd.MM.yyyy']}
                    minDate={getTomorrowDate()}
                    fixedHeight
                    onChangeRaw={(e) => {
                      e.preventDefault();
                    }}
                    note="Дата записи является предварительной"
                    errorMessage={
                      errors.date && touched.date ? errors.date : undefined
                    }
                  />
                </FieldInner>
              </Field>
            </FieldsGroup>

            <TextAreaWrapper isMobile={isMobile}>
              <StockFilterTextArea
                id="comment"
                name="comment"
                label="Комментарий"
                value={values.comment}
                onChange={handleChange}
              />
            </TextAreaWrapper>

            <CheckboxAgreementWrapper isMobile={isMobile}>
              <CheckboxAgreement
                checked={userAgreement}
                onChange={() => setUserAgreement(!userAgreement)}
              />
            </CheckboxAgreementWrapper>

            <Actions isMobile={isMobile}>
              <SendButton
                variant="contained"
                type="submit"
                disabled={!isSubmitting ? !userAgreement : true}
              >
                {!isSubmitting ? buttonTitle : <Loader color="#fff" />}
              </SendButton>
            </Actions>
          </Main>
        </React.Fragment>
      ) : (
        <SuccessForm />
      )}
    </Component>
  );
}

export default ServiceForm;

const Component = styled.form<{ isMobile?: boolean }>`
  padding: 40px 60px;
  background: ${(props) => props.theme.white_1};
  box-shadow: ${(props) => props.theme.blackShadow100};
  border-radius: 10px;

  ${media.mobile(css`
    padding: 30px 15px;
  `)}

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 30px 15px !important;
    `}
`;

const Title = styled.p<{ isMobile?: boolean }>`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 24px;
    line-height: 30px;
  `)}

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 24px !important;
      line-height: 30px !important;
    `}
`;

const Main = styled.div`
  margin-top: 30px;
`;

const FieldsGroup = styled.div<{ isMobile?: boolean }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: min-content;
  gap: 30px;

  ${media.tablet(css`
    grid-template-columns: repeat(2, 1fr);
  `)}

  ${media.mobile(css`
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  `)}

  ${({ isMobile }) =>
    isMobile &&
    css`
      grid-template-columns: repeat(1, 1fr) !important;
      gap: 20px !important;
    `}
`;

const Field = styled.div`
  position: relative;
`;

const FieldInner = styled.div`
  position: relative;
`;

const TextAreaWrapper = styled.div<{ isMobile?: boolean }>`
  margin-top: 25px;

  ${media.mobile(css`
    textarea {
      min-height: 78px;
    }
  `)}

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: 25px !important;

      textarea {
        min-height: 78px !important;
      }
    `}
`;

const CheckboxAgreementWrapper = styled.div<{ isMobile?: boolean }>`
  margin-top: 20px;

  ${media.mobile(css`
    margin-top: 30px;
  `)}

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: 30px !important;
    `}
`;

const Actions = styled.div<{ isMobile?: boolean }>`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  ${media.mobile(css`
    margin-top: 20px;
  `)}

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: 20px !important;
    `}
`;

const SendButton = styled(Button)`
  max-width: 289px;
`;
