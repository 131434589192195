import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { media } from '@/utils/mixin';
import StockFilterSelect from '@/components/StockFilterSelect';
import { OptionType } from '@/typings/common';
import Button, { ButtonNextLink } from '@/components/Button';

import { ServiceDealersInitialTab, ServiceDealersProps } from './types';
import { serviceDealersTabsData } from './constants';
import ServiceDealersTabs from './components/ServiceDealersTabs';
import ServiceDealersList from './components/ServiceDealersList';
import ServiceDealersSlider from './components/ServiceDealersSlider';
import ServiceDealersMap from './components/ServiceDealersMap';

function ServiceDealers({
  id,
  dealers,
  options,
  buttonTitle,
  buttonLink,
}: ServiceDealersProps) {
  const initialTab = options?.initialTab ?? 'list';

  const [activeTab, setActiveTab] = useState<
    OptionType<ServiceDealersInitialTab>
  >(serviceDealersTabsData[initialTab === 'list' ? 0 : 1]);
  const [isShowMore, setShowMore] = useState(false);
  const [filteredDealers, setFilteredDealers] = useState(dealers);
  const isMobile = useMedia('(max-width: 767px)');

  const brandsOptions = [
    { label: 'Все марки', value: 'Все марки' },
    ...(options?.brands ?? []),
  ];

  const citiesOptions = [
    { label: 'Все города', value: 'Все города' },
    ...(options?.cities ?? []),
  ];

  const [selectedBrand, setSelectedBrand] = useState<OptionType>(
    brandsOptions[0]
  );

  const [selectedCity, setSelectedCity] = useState<OptionType>(
    citiesOptions[0]
  );

  const handleShowMore = () => {
    setShowMore(!isShowMore);
  };

  const handleChangeBrand = (brand: OptionType) => {
    setSelectedBrand(brand);

    if (brand.label === 'Все марки') {
      setFilteredDealers(
        dealers.filter((dealer) =>
          selectedCity.label !== 'Все города'
            ? dealer.address.includes(selectedCity.label)
            : true
        )
      );
    } else {
      setFilteredDealers(
        dealers.filter(
          (dealer) =>
            (selectedCity.label !== 'Все города'
              ? dealer.address.includes(selectedCity.label)
              : true) && dealer.brands.includes(brand.label)
        )
      );
    }

    setShowMore(false);
  };

  const handleChangeCity = (city: OptionType) => {
    setSelectedCity(city);

    if (city.label === 'Все города') {
      setFilteredDealers(
        dealers.filter((dealer) =>
          selectedBrand.label !== 'Все марки'
            ? dealer.brands.includes(selectedBrand.label)
            : true
        )
      );
    } else {
      setFilteredDealers(
        dealers.filter(
          (dealer) =>
            (selectedBrand.label !== 'Все марки'
              ? dealer.brands.includes(selectedBrand.label)
              : true) && dealer.address.includes(city.label)
        )
      );
    }

    setShowMore(false);
  };

  if (!dealers || dealers.length === 0) {
    return null;
  }

  return (
    <Component>
      {options?.brands && options?.cities && (
        <Filters>
          <Filter>
            <StockFilterSelect
              label="Марка"
              value={selectedBrand}
              options={brandsOptions}
              onChange={handleChangeBrand}
            />
          </Filter>

          <Filter>
            <StockFilterSelect
              label="Город"
              value={selectedCity}
              options={citiesOptions}
              onChange={handleChangeCity}
            />
          </Filter>
        </Filters>
      )}

      <ServiceDealersTabs
        options={serviceDealersTabsData}
        activeTab={activeTab.value}
        onChange={setActiveTab}
      />

      <Content>
        {activeTab.value === 'list' ? (
          <React.Fragment>
            {!isMobile ? (
              <React.Fragment>
                <ServiceDealersList
                  dealers={
                    !isShowMore ? filteredDealers.slice(0, 4) : filteredDealers
                  }
                />

                {filteredDealers && filteredDealers.length > 4 && (
                  <Actions>
                    <ShowMore variant="outlined" onClick={handleShowMore}>
                      {!isShowMore ? 'Показать еще' : 'Скрыть'}
                    </ShowMore>
                  </Actions>
                )}
              </React.Fragment>
            ) : (
              <ServiceDealersSlider id={id} dealers={filteredDealers} />
            )}
          </React.Fragment>
        ) : (
          <ServiceDealersMap dealers={filteredDealers} />
        )}
      </Content>

      {buttonTitle && buttonLink && (
        <ButtonWrapper>
          <StyledButtonNextLink variant="outlined" to={buttonLink}>
            {buttonTitle}
          </StyledButtonNextLink>
        </ButtonWrapper>
      )}
    </Component>
  );
}

export default ServiceDealers;

const Component = styled.div`
  position: relative;
`;

const Filters = styled.div`
  display: flex;
  margin-bottom: 40px;

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const Filter = styled.div`
  flex: 1 1 445px;
  max-width: 445px;

  ${media.mobile(css`
    flex: auto;
    max-width: none;
  `)}

  &:not(:first-child) {
    margin-left: 20px;

    ${media.mobile(css`
      margin-left: 0;
      margin-top: 20px;
    `)}
  }
`;

const Content = styled.div`
  margin-top: 20px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const ShowMore = styled(Button)`
  max-width: 289px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const StyledButtonNextLink = styled(ButtonNextLink)`
  max-width: 289px;
`;
