import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { media } from '@/utils/mixin';
import Link from '@/components/Link';
import ArrowIcon from '@/assets/svg/arrow-down.svg';
import { colors } from '@/constants/theme';

import { ServiceListProps } from './types';
import ServiceNode from './components/ServiceNode';

function ServiceList({ title, children, url }: ServiceListProps) {
  const isMobile = useMedia('(max-width: 767px)');
  const [isOpened, setOpened] = useState(false);
  const listVisibility = !isMobile || (isMobile && isOpened);

  const handleTitleClick = () => {
    if (isMobile) {
      setOpened(!isOpened);
    }
  };

  return (
    <Component>
      <Header onClick={handleTitleClick} isOpened={isOpened}>
        {url ? <TitleLink to={url}>{title}</TitleLink> : <Title>{title}</Title>}
        {isMobile && <ArrowIcon />}
      </Header>

      {listVisibility && (
        <Nodes>
          {children &&
            children.map((item, index) => (
              <ServiceNode key={index} {...item} />
            ))}
        </Nodes>
      )}
    </Component>
  );
}

export default ServiceList;

const Component = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div<{ isOpened?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.mobile(css`
    padding: 9px 0;

    svg {
      flex: 0 0 15px;
      width: 15px;
      height: 15px;
      transition: 0.3s;
      margin-left: 20px;

      path {
        fill: ${(props) => props.theme.black100};
      }
    }
  `)}

  ${({ isOpened }) =>
    isOpened &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 20px;
    line-height: 23px;
  `)}
`;

const TitleLink = styled(Link)`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${(props) => props.theme.black};

  &:hover {
    color: ${colors.main};
  }

  ${media.mobile(css`
    font-size: 20px;
    line-height: 23px;
  `)}
`;

const Nodes = styled.div`
  margin-left: -14px;
`;
