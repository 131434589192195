import React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';

import { DatePickerStyles } from '@/components/styles';
import DateIcon from '@/assets/svg/date-icon.svg';
import { StockFilterDatePickerProps } from '@/components/StockFilterDatePicker/types';
import { colors } from '@/constants/theme';

function StockFilterDatePicker({
  label,
  note,
  errorMessage,
  ...props
}: StockFilterDatePickerProps) {
  return (
    <Component>
      {label && (
        <Label>
          {label} {note && <Star>*</Star>}
        </Label>
      )}

      <Picker>
        <DatePicker {...props} customInput={<Input />} />
        <StyledDateIcon />
      </Picker>

      {note && (
        <Note>
          <Star>*</Star> {note}
        </Note>
      )}

      {errorMessage && <Error>{errorMessage}</Error>}
    </Component>
  );
}

export default StockFilterDatePicker;

const Component = styled.div`
  position: relative;
  width: 100%;
`;

const Label = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.blue100};
`;

const Picker = styled.div`
  position: relative;
  ${DatePickerStyles};

  .react-datepicker {
    border: 1px solid ${(props) => props.theme.gray1000};
  }
`;

const Input = styled.input`
  display: block;
  width: 100%;
  padding: 11px 15px 10px;
  height: 40px;
  font-size: 16px;
  line-height: 19px;
  background: ${(props) => props.theme.white_1};
  color: ${(props) => props.theme.black200};
  border: 1px solid ${(props) => props.theme.gray1000};
  border-radius: 6px;

  &::placeholder {
    color: ${(props) => props.theme.gray1500};
    opacity: 1;
  }
`;

const Note = styled.div`
  font-size: 14px;
  line-height: 16px;
  margin-top: 10px;
  color: ${(props) => props.theme.blue100};
`;

const Star = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.pink};
`;

const StyledDateIcon = styled(DateIcon)`
  z-index: 1;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  width: 18px;
  height: 18px;

  path {
    fill: ${(props) => props.theme.black100_alpha50};
  }
`;

const Error = styled.div`
  position: absolute;
  color: ${colors.red600};
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  bottom: -18px;
`;
