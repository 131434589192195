import React from 'react';
import styled, { css } from 'styled-components';
import GoogleMapReact from 'google-map-react';

import { useMedia } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { getMapProps } from '@/utils/map';

import { ServiceDealersMapProps } from './types';
import Marker from './components/Marker';

function ServiceDealersMap({ dealers }: ServiceDealersMapProps) {
  const isMobile = useMedia('(max-width: 767px)');

  if (!dealers || dealers.length === 0) {
    return null;
  }

  const mapProps = getMapProps(
    dealers.map(({ lat, lng }) => ({
      latitude: lat ?? 0,
      longitude: lng ?? 0,
    }))
  );

  return (
    <Component>
      <MapWrapper>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.NEXT_PUBLIC_API_KEY_GOOGLE_MAP ?? '',
          }}
          center={mapProps.center}
          defaultZoom={mapProps.zoom}
        >
          {dealers.map((dealer, index) => (
            <Marker
              key={index}
              image={!isMobile ? dealer.image : undefined}
              {...dealer}
            />
          ))}
        </GoogleMapReact>
      </MapWrapper>
    </Component>
  );
}

export default ServiceDealersMap;

const Component = styled.div`
  position: relative;
  padding-top: 41.177%;
  border-radius: 10px;
  overflow: hidden;

  ${media.tablet(css`
    padding-top: 65.177%;
  `)}

  ${media.mobile(css`
    padding-top: 138.487%;
    border-radius: initial;
  `)}
`;

const MapWrapper = styled.div`
  position: absolute;
  inset: 0;
`;
