import React, { useCallback, useState } from 'react';

import { Nullable } from '@tager/web-core';

import { ServiceDealerCardProps } from '@/components/ServiceDealers/components/ServiceDealerCard/types';

import { ServiceContext } from './ServiceContext';

export const ServiceProvider: React.FC = ({ children }) => {
  const [selectedDealer, setNewSelectedDealer] =
    useState<Nullable<Omit<ServiceDealerCardProps, 'onClick'>>>(null);

  const setSelectedDealer = useCallback(
    (dealer: Nullable<Omit<ServiceDealerCardProps, 'onClick'>>) => {
      setNewSelectedDealer(dealer);
    },
    []
  );

  return (
    <ServiceContext.Provider value={{ selectedDealer, setSelectedDealer }}>
      {children}
    </ServiceContext.Provider>
  );
};
