import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ContentContainer } from '@/modules/Service/common.styles';
import ServiceDealers from '@/components/ServiceDealers';

import { DealersBlockProps } from './types';

function DealersBlock({ id, title, dealers, options }: DealersBlockProps) {
  if (!dealers || dealers.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{title}</Title>

        <ServiceDealersWrapper>
          <ServiceDealers id={id} dealers={dealers} options={options} />
        </ServiceDealersWrapper>
      </ContentContainer>
    </Component>
  );
}

export default DealersBlock;

const Component = styled.section`
  padding: 30px 0;

  ${media.mobile(css`
    padding: 20px 0;
  `)}
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  `)}
`;

const ServiceDealersWrapper = styled.div`
  margin-top: 20px;
`;
