import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { OptionType } from '@/typings/common';

import { ServiceDealersTabsProps } from './types';

function ServiceDealersTabs<T>({
  options,
  activeTab,
  onChange,
}: ServiceDealersTabsProps<T>) {
  const handleTabClick = (option: OptionType<T>) => {
    onChange?.(option);
  };

  if (!options || options.length === 0) {
    return null;
  }

  return (
    <Component>
      {options.map((option, index) => (
        <Tab
          key={index}
          isActive={activeTab === option.value}
          onClick={() => handleTabClick(option)}
        >
          {option.label}
        </Tab>
      ))}
    </Component>
  );
}

export default ServiceDealersTabs;

const Component = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Tab = styled.button<{ isActive: boolean }>`
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.blue100};

  &:not(:first-child) {
    margin-left: 20px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom: 2px solid ${colors.main};
      color: ${(props) => props.theme.black};
    `}
`;
