import React from 'react';
import styled, { css } from 'styled-components';

import {
  convertThumbnailToPictureImage,
  convertThumbnailToPictureProps,
} from '@tager/web-modules';

import { ContentContainer } from '@/modules/Service/common.styles';
import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';
import { ButtonNextLink } from '@/components/Button';

import { BenefitsBlockProps } from './types';

function BenefitsBlock({
  image,
  imageMobile,
  title,
  titleColor,
  benefits,
  buttonTitle,
  buttonLink,
}: BenefitsBlockProps) {
  if (!benefits || benefits.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Inner>
          <Image
            loading="lazy"
            desktop={convertThumbnailToPictureImage(image)}
            laptop={convertThumbnailToPictureImage(image)}
            tabletSmall={convertThumbnailToPictureImage(image)}
            mobile={convertThumbnailToPictureImage(imageMobile ?? image)}
          />

          <Content>
            <Title color={titleColor}>{title}</Title>

            <Benefits>
              {benefits &&
                benefits.length !== 0 &&
                benefits.map(
                  ({ icon, description, descriptionColor }, index) => (
                    <Benefit key={index}>
                      {icon && icon.url && (
                        <BenefitImage
                          loading="lazy"
                          {...convertThumbnailToPictureProps(icon)}
                        />
                      )}

                      <BenefitDescription color={descriptionColor}>
                        {description}
                      </BenefitDescription>
                    </Benefit>
                  )
                )}
              {buttonTitle && buttonLink && (
                <ButtonWrapper>
                  <ButtonNextLink variant="contained" to={buttonLink}>
                    {buttonTitle}
                  </ButtonNextLink>
                </ButtonWrapper>
              )}
            </Benefits>
          </Content>
        </Inner>
      </ContentContainer>
    </Component>
  );
}

export default BenefitsBlock;

const Component = styled.section`
  padding: 30px 0;

  ${media.mobile(css`
    padding: 20px 0;
  `)}
`;

const Inner = styled.div`
  display: flex;

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const Image = styled(Picture)`
  flex: 1 1 50%;
  max-width: 50%;

  ${media.mobile(css`
    flex: auto;
    max-width: none;
    padding-top: 56.669%;
  `)}

  picture,
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px 0 0 10px;

    ${media.mobile(css`
      position: absolute;
      inset: 0;
      border-radius: 10px 10px 0 0;
    `)}
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 50%;
  max-width: 50%;
  padding: 34px 47px 34px 76px;
  background: ${(props) => props.theme.gray_1};
  border-radius: 0 10px 10px 0;

  ${media.tablet(css`
    padding: 40px 20px;
  `)}

  ${media.mobile(css`
    padding: 30px 20px;
    flex: auto;
    max-width: none;
    border-radius: 0 0 10px 10px;
  `)}
  }
`;

const Title = styled.p<{ color?: string }>`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  `)}

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;

const Benefits = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 45px 58px;
  margin-top: 51px;

  ${media.tablet(css`
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin-top: 30px;
  `)}
`;

const Benefit = styled.div`
  display: flex;
  align-items: center;

  ${media.tablet(css`
    flex-direction: row;
    align-items: center;
  `)}
`;

const BenefitImage = styled(Picture)`
  flex: 0 0 50px;
  height: 50px;
  margin-right: 20px;

  ${media.tablet(css`
    flex: 0 0 40px;
    height: 40px;
    margin-right: 10px;
  `)}

  picture,
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  img {
    object-fit: cover;
  }
`;

const BenefitDescription = styled.p<{ color?: string }>`
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.black};

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  max-width: 289px;

  ${media.tablet(
    css`
      max-width: none;
    `
  )}
`;
