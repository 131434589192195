import React from 'react';

import OffersBlock from '@/modules/Service/blocks/OffersBlock';
import { useServiceMainData } from '@/modules/Service/pages/ServiceMain/hooks';
import { getServicePosts } from '@/store/reducers/service';
import { useTypedSelector } from '@/store/store';

function Offers() {
  const { offersTitle } = useServiceMainData();
  const posts = useTypedSelector(getServicePosts);

  if (!posts || posts.length === 0) {
    return null;
  }

  return (
    <OffersBlock
      id={2}
      title={offersTitle ?? ''}
      offers={posts.map(({ title, url, image }) => ({
        title: title ?? '',
        url: url ?? '',
        image,
      }))}
    />
  );
}

export default Offers;
