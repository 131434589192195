import React from 'react';
import styled from 'styled-components';

import { ServiceProvider } from '@/modules/Service/ServiceProvider';

import Hero from './components/Hero';
import Form from './components/Form';
import Services from './components/Services';
import Offers from './components/Offers';
import Benefits from './components/Benefits';
import Dealers from './components/Dealers';

export function ServiceBrand() {
  return (
    <ServiceProvider>
      <Component>
        <Hero />
        <Form />
        <Services />
        <Offers />
        <Benefits />
        <Dealers />
      </Component>
    </ServiceProvider>
  );
}

const Component = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
