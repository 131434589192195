import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import Link from '@/components/Link';
import SuccessIcon from '@/assets/svg/success-check.svg';

import { ServiceBannerProps } from './types';

function ServiceBanner({
  title,
  image,
  imageMobile,
  subtitle,
  description,
  link,
  list,
}: ServiceBannerProps) {
  const component = (
    <Component>
      <Content>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {description && <Description>{description}</Description>}
        {list && list.length > 0 && (
          <List>
            {list.map((item, index) => (
              <ListItem key={index}>
                <SuccessIcon />
                <ListText>{item}</ListText>
              </ListItem>
            ))}
          </List>
        )}
      </Content>

      {image && (
        <Background
          loading="lazy"
          desktop={convertThumbnailToPictureImage(image)}
          laptop={convertThumbnailToPictureImage(image)}
          tabletSmall={convertThumbnailToPictureImage(image)}
          mobile={convertThumbnailToPictureImage(imageMobile ?? image)}
        />
      )}
    </Component>
  );

  return !link ? (
    <React.Fragment>{component}</React.Fragment>
  ) : (
    <Link to={link}>{component}</Link>
  );
}

export default ServiceBanner;

const Component = styled.div`
  position: relative;
  padding: 70px 60px;
  min-height: 290px;

  ${media.mobile(css`
    display: flex;
    align-items: flex-end;
    min-height: 434px;
    padding: 20px;
  `)}
`;

const Content = styled.div`
  z-index: 2;
  position: relative;
  max-width: 540px;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  margin: 0;
  color: ${colors.white};

  ${media.tablet(css`
    font-size: 30px;
    line-height: 35px;
  `)}
`;

const Subtitle = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  margin-top: 20px;
  color: ${colors.white};

  ${media.tablet(css`
    font-size: 24px;
    line-height: 28px;
    margin-top: 10px;
  `)}
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-top: 10px;
  color: ${colors.white};
`;

const Background = styled(Picture)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  img {
    object-fit: cover;
  }
`;

const List = styled.div`
  margin-top: 10px;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;

  svg {
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
  }

  &:not(:first-child) {
    margin-top: 5px;
  }
`;

const ListText = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  margin-left: 10px;
  color: ${colors.white};

  ${media.tablet(css`
    font-size: 18px;
    line-height: 21px;
  `)}
`;
