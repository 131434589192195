import React from 'react';
import styled, { css } from 'styled-components';

import ServicesBlock from '@/modules/Service/blocks/ServicesBlock';
import { media } from '@/utils/mixin';
import { useTypedSelector } from '@/store/store';
import { getServiceSingleLanding } from '@/store/reducers/service';
import defaultServiceImage from '@/assets/images/service/defaultServiceImage.png';

function Services() {
  const serviceSingleLanding = useTypedSelector(getServiceSingleLanding);

  if (!serviceSingleLanding) {
    return null;
  }

  const { isPopular, moreServices } = serviceSingleLanding;

  if (!moreServices || moreServices.length === 0) {
    return null;
  }

  return (
    <Component>
      <ServicesBlock
        id={1}
        title={`${isPopular ? 'Популярные' : 'Похожие'} услуги`}
        items={moreServices.map(({ title, url, image }) => ({
          title,
          link: url,
          image: { url: image ? image : defaultServiceImage },
        }))}
        withoutImage
      />
    </Component>
  );
}

export default Services;

const Component = styled.section`
  padding: 30px 0;

  ${media.mobile(css`
    padding: 20px 0;
  `)}
`;
