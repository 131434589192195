import React from 'react';

import OffersBlock from '@/modules/Service/blocks/OffersBlock';
import {
  getServiceBrandLanding,
  getServicePosts,
} from '@/store/reducers/service';
import { useTypedSelector } from '@/store/store';

function Offers() {
  const servicePosts = useTypedSelector(getServicePosts);
  const serviceBrandLanding = useTypedSelector(getServiceBrandLanding);

  if (
    !servicePosts ||
    servicePosts.length === 0 ||
    !serviceBrandLanding?.blogTags ||
    serviceBrandLanding.blogTags.length === 0
  ) {
    return null;
  }

  return (
    <OffersBlock
      id={1}
      title={`Акции по сервису ${serviceBrandLanding?.name}`}
      offers={servicePosts.map(({ title, url, image, landscapeImage }) => ({
        title: title ?? '',
        url: url ?? '',
        image:
          servicePosts.length !== 1 ? image : landscapeImage ?? { url: '' },
      }))}
    />
  );
}

export default Offers;
