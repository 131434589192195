import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/Service/common.styles';
import { Header as SliderWrapperHeader } from '@/components/LandingWidgets/components/SliderWrapper';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import man from '@/assets/images/service/man.png';
import ServicesSlider from '@/components/ServicesSlider';

import { ServicesBlockProps } from './types';

function ServicesBlock({ id, withoutImage, title, items }: ServicesBlockProps) {
  if (!items || items.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Inner>
          {!withoutImage && <Image loading="lazy" src={man} />}

          <Right>
            <Title>{title}</Title>

            <Services>
              <ServicesSlider id={id} items={items} />
            </Services>
          </Right>
        </Inner>
      </ContentContainer>
    </Component>
  );
}

export default ServicesBlock;

const Component = styled.section``;

const Inner = styled.div`
  display: flex;
`;

const Image = styled(Picture)`
  flex: 0 0 290px;
  margin-top: 44px;
  margin-right: 21px;

  ${media.tablet(css`
    display: none;
  `)}
`;

const Right = styled.div`
  width: 100%;
  min-width: 0;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  `)}
`;

const Services = styled.div`
  margin-top: 30px;

  ${media.tablet(css`
    margin-top: 20px;
  `)}

  ${SliderWrapperHeader} {
    top: -70px;

    ${media.tablet(
      css`
        top: -50px;
      `
    )}
  }
`;
