import React from 'react';
import { SwiperSlide } from 'swiper/react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { SliderWrapper } from '@/components/LandingWidgets';
import { media } from '@/utils/mixin';

import ServiceCard, {
  Component as ServiceCardComponent,
} from './components/ServiceCard';
import { ServicesSliderProps } from './types';

function ServicesSlider({ id, items }: ServicesSliderProps) {
  const isMobile = useMedia('(max-width: 767px)');

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <Component>
      <SliderWrapper
        id={id}
        options={{
          slidesPerView: !isMobile ? undefined : 1,
          withoutNavs: items.length < 5 || isMobile,
          withoutPagination: !isMobile,
          spaceBetween: 20,
        }}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            <ServiceCard {...item} />
          </SwiperSlide>
        ))}
      </SliderWrapper>
    </Component>
  );
}

export default ServicesSlider;

const Component = styled.div`
  .swiper-slide {
    max-width: 289px;
    height: auto;

    ${ServiceCardComponent} {
      height: 100%;
    }

    ${media.mobile(css`
      max-width: none;
    `)}
  }
`;
